import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(public)": [~35,[4]],
		"/(public)/about": [36,[4]],
		"/(public)/ai-tools": [37,[4]],
		"/(public)/auth/forgot-password": [38,[4,5]],
		"/(public)/auth/login": [39,[4,5]],
		"/(public)/auth/otp": [40,[4,5]],
		"/(public)/auth/passwordless": [41,[4,5]],
		"/(public)/auth/signup": [42,[4,5]],
		"/(public)/auth/verify": [~43,[4,5]],
		"/(public)/auth/verify/change-email": [~44,[4,5]],
		"/(public)/auth/verify/change-password": [~45,[4,5]],
		"/(public)/blog": [46,[4]],
		"/(public)/blog/posts": [47,[4]],
		"/(private)/(user)/bookmarks": [~6,[2]],
		"/(public)/category/[categorySlug]": [48,[4]],
		"/(public)/community/[communitySlug]": [49,[4]],
		"/(public)/community/[communitySlug]/join": [50,[4]],
		"/(public)/company/auth/complete": [~56,[4]],
		"/(public)/company/auth/invite/[inviteId]": [~57,[4]],
		"/(public)/company/auth/login": [58,[4]],
		"/(public)/company/auth/login/continue": [~59,[4]],
		"/(public)/company/auth/signup": [~60,[4]],
		"/(public)/company/[companySlug]": [51,[4]],
		"/(public)/company/[companySlug]/claim": [52,[4]],
		"/(public)/company/[companySlug]/jobs": [53,[4]],
		"/(public)/company/[companySlug]/jobs/[jobSlug]": [54,[4]],
		"/(public)/company/[companySlug]/jobs/[jobSlug]/apply": [55,[4]],
		"/(public)/contact": [61,[4]],
		"/(private)/dashboard/admin": [9],
		"/(private)/dashboard/community/[...section]": [10],
		"/(private)/dashboard/employer/account": [~12,[3]],
		"/(private)/dashboard/employer/api": [~13,[3]],
		"/(private)/dashboard/employer/company": [~14,[3]],
		"/(private)/dashboard/employer/company/employees": [15,[3]],
		"/(private)/dashboard/employer/home": [16,[3]],
		"/(private)/dashboard/employer/jobs": [~17,[3]],
		"/(private)/dashboard/employer/jobs/ai": [~26,[3]],
		"/(private)/dashboard/employer/jobs/create": [~27,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]": [~18,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]/applications": [19,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]/applications/[applicationId]": [20,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]/edit": [21,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]/form": [~22,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]/form/applications": [23,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]/form/applications/[applicationId]": [24,[3]],
		"/(private)/dashboard/employer/jobs/[jobId]/pay": [~25,[3]],
		"/(private)/dashboard/employer/notifications": [~28,[3]],
		"/(private)/dashboard/employer/payments/[paymentId]": [29,[3]],
		"/(private)/dashboard/employer/payments/[paymentId]/verify": [~30,[3]],
		"/(private)/dashboard/employer/settings/[...path]": [31,[3]],
		"/(private)/dashboard/employer/tickets/[...path]": [32,[3]],
		"/(private)/dashboard/employer/[...section]": [11,[3]],
		"/(private)/dashboard/talent/[...section]": [33],
		"/(private)/dashboard/user": [34],
		"/(public)/faq": [62,[4]],
		"/(public)/guides/for-communities": [63,[4]],
		"/(public)/guides/for-employers": [64,[4]],
		"/(public)/guides/for-jobseekers": [65,[4]],
		"/(public)/guides/for-talent": [66,[4]],
		"/(public)/partners": [67,[4]],
		"/(public)/post": [68,[4]],
		"/(public)/post/new": [69,[4]],
		"/(public)/post/new/add-company-details": [70,[4]],
		"/(public)/post/new/complete-purchase": [71,[4]],
		"/(public)/pricing": [72,[4]],
		"/(public)/pricing/simple": [73,[4]],
		"/(public)/pricing/special": [74,[4]],
		"/(public)/pricing/standard": [75,[4]],
		"/(public)/privacy": [76,[4]],
		"/(private)/(user)/recommended": [~7,[2]],
		"/(public)/search": [77,[4]],
		"/sentry-example": [82],
		"/(private)/(user)/settings": [~8,[2]],
		"/(public)/sponsorship": [78,[4]],
		"/(public)/terms": [79,[4]],
		"/(public)/test2": [81,[4]],
		"/(public)/test": [~80,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';